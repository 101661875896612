/* print-prescription */

.print-prescription {

  .hospital-logo {
    text-align: center;
    padding-block: 15px;
  }

  .source-DoP {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
    padding-inline: 20px 160px;
    padding-block: 16px;
    font-size: 0.755rem;
    font-weight: 500;

    span {
      color: #343a40;
      font-weight: bold;
    }
  }

  .clinic-details {
    padding-block: 20px;


    table {
      width: 100%;

      th, td {
        border: 1px solid black;
      }
    }
  }

  .clinic-details-mobile {
    display: none;
  }

  .prescription-details-mobile {
    display: none;
  }

  .presc-barcode {
    display: flex;
    //gap: 40px;
    justify-content: space-between;
    padding-inline: 20px 160px;
    padding-block: 15px;

    > :first-child {
      font-size: 0.785rem;
      font-weight: 500;
      padding-block: 25px;
    }

    span {
      font-size: 0.875rem;
      color: #343a40;
      font-weight: bold;
    }
  }

  .powered-by {
    text-align: center;
    font-size: 0.685rem;
    padding-block: 10px;
    font-weight: 500;

    span {
      color: #343a40;
      font-size: 0.699rem;
      font-weight: bold;
    }
  }

  .website {
    text-align: center;
    font-size: 0.685rem;
    font-weight: 500;

    span {
      font-size: 0.699rem;
      color: #343a40;
      font-weight: bold;
    }

  } .phone {
    text-align: center;
    font-size: 0.685rem;
    font-weight: 500;
     margin-top: -29px;
    background: white;
    width: fit-content;
    margin-inline: auto;
    padding: 5px 10px;

        span {
      font-size: 0.699rem;
      color: #343a40;
      font-weight: bold;
    }
  }

  .disclaimer {
    text-align: center;
    font-size: 0.685rem;
    font-weight: 500;
  }

  @media (max-width: 550px) {
    .source-DoP {
      padding-inline: 6px 60px;
    }

    .clinic-details, .prescription-details {
      display: none;
    }

    .clinic-details-mobile {
      display: block;
      border: dashed 2px gray;
      padding: 10px;
      border-radius: 10px;
      margin-bottom: 10px;

      > :nth-child(odd) {
        background: #FFFFFF;
        box-shadow: 0 0 3px #888888;
      }

      > div {
        padding: 10px;
        border-radius: 5px;

        > :first-child {
          font-weight: 700;
          font-size: 1em;
        }
      }
    }

    .prescription-details-mobile {
      display: block;
      border: dashed 2px gray;
      padding: 10px;
      border-radius: 10px;
      margin-block: 18px;

      > div {
        border-radius: 10px;

        > :nth-child(odd) {
          background: #FFFFFF;
          box-shadow: 0 0 3px #888888;
        }

        > :nth-child(even) {
          margin-block: 10px;
          background: #FFFFFF;
          box-shadow: 0 0 3px #888888;
        }

        > div {
          padding: 10px;
          border-radius: 10px;
          display: grid;
          row-gap: 20px;

          > div {
            display: grid;

            > :first-child {
              font-weight: 700;
              font-size: 1em;
            }
          }
        }


      }
    }

    .presc-barcode {
      display: grid;
      justify-content: center;
      justify-items: center;
      text-align: center;
      padding: 0;
    }
  }
}
