/* SwitchHospital */

.switchHospital {
  background: $sidebar-light-bg;
  box-shadow: 0 0.5rem 1rem #00000026 !important;
  max-width: 430px;
  height: 300px;
  margin: auto;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;

  input {
    accent-color: green;
  }

  button {
    color: #FFFFFF;
    background: #123366;
    border-radius: 5px;
    padding: 10px 25px;
   
    margin-inline: auto;
    border: #123366;
  }
}
