.dropzone {
  text-align: center;
  padding: 30px;
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  color: #bdbdbd;
  cursor: pointer;
  margin-bottom: 20px;
}

.selected-file-wrapper {
  text-align: center;
}

.selected-file {
  color: #000;
  font-weight: bold;
}

#sign-up-background-image {
  width: 100%;
  display: block;
  margin: auto;
  margin-top: 100px;
}

.modal-content {
  background-color: $white;
}
.modal-70w {
  width: 70%;
  max-width: none !important;
  @media (max-width: 800px) {
    width: 100%;
    //max-width: none !important;
  }
}

.modal-fullscreen {
  width: 80% !important;
  max-width: none !important;
  height: 100% !important;
  // margin: 0;
  .modal-content {
    height: 70% !important;
  }
}

.btn-filter-vitals {
  color: rgba(197, 4, 78, 0.52);
  border-color: rgba(197, 4, 78, 0.52);
  cursor: pointer;
  &:active {
    background-color: rgba(197, 4, 78, 0.52);
    color: $white;
  }
  &:hover {
    background-color: rgba(197, 4, 78, 0.52);
    color: $white;
  }
  input[type="radio"]:checked + label {
    background-color: rgba(21, 189, 133, 0.52);
    color: $white;
  }
  .btn-check:checked + .btn-outline-primary {
    color: $white;
    background-color: rgba(197, 4, 78, 0.52);
    border-color: rgba(197, 4, 78, 0.52);
  }
}

.btn-filter-lab {
  color: rgba(4, 112, 47, 0.52);
  border-color: rgba(4, 112, 47, 0.52);
  &:active {
    background-color: rgba(4, 112, 47, 0.52);
    color: $white;
  }
  &:hover {
    background-color: rgba(4, 112, 47, 0.52);
    color: $white;
  }
}

.btn-filter-radiology {
  color: rgba(3, 22, 192, 0.52);
  border-color: rgba(3, 22, 192, 0.52);
  &:active {
    background-color: rgba(3, 22, 192, 0.52);
    color: $white;
  }
  &:hover {
    background-color: rgba(3, 22, 192, 0.52);
    color: $white;
  }
}

.btn-filter-prescription {
  color: rgba(207, 84, 2, 0.52);
  border-color: rgba(207, 84, 2, 0.52);
  &:active {
    background-color: rgba(207, 84, 2, 0.52);
    color: $white;
  }
  &:hover {
    background-color: rgba(207, 84, 2, 0.52);
    color: $white;
  }
  input:active {
    background-color: rgba(207, 84, 2, 0.52);
    color: $white;
  }
}

.btn-filter-diagnosis {
  color: rgba(192, 2, 209, 0.52);
  border-color: rgba(192, 2, 209, 0.52);
  &:active {
    background-color: rgba(192, 2, 209, 0.52);
    color: $white;
  }
  &:hover {
    background-color: rgba(192, 2, 209, 0.52);
    color: $white;
  }
}

.btn-facebook-custom {
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.54);
  height: 50px;
  width: "100%";
  border: none;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 4px 0px;
  font-size: 16px;
  line-height: 48px;
  display: block;
  border-radius: 1px;
  transition: background-color 0.218s ease 0s, border-color 0.218s ease 0s,
    box-shadow 0.218s ease 0s;
  font-family: Roboto, arial, sans-serif;
  cursor: pointer;
  user-select: none;

  &:hover {
    box-shadow: rgba(66, 133, 244, 0.3) 0px 0px 3px 3px;
  }
  i {
    color: #3b579d;
    float: left;
    font-size: 1.5rem;
    padding-top: 10px;
  }
}

.btn-google-custom {
  background-color: $white;
  color: $black;
  border-color: $black;
  &:hover {
    background-color: rgb(243, 248, 255);
    color: #3b579d;
  }
  i {
    margin-right: 0.3125rem;
    font-size: inherit;
    color: #3b579d;
  }
}

.btn-themed {
  background-color: $theme-blue;
  color: $white;
  border-color: $theme-blue;
  &:disabled {
    background-color: $theme-blue;
    border-color: $theme-blue;
  }
  &:hover {
    background-color: darken($theme-blue, 5%);
    border-color: $theme-blue;
    color: $white;
  }
}

.btn-themed-outline {
  background-color: $white;
  color: $theme-blue;
  border-color: $theme-blue;
  border: 1px solid darken(#e4e4e4, 5%);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 4px 0px;
  &:disabled {
    background-color: $white;
    border-color: $theme-blue;
  }
  &:hover {
    background-color: darken($theme-blue, 5%);
    border-color: $theme-blue;
    color: $white;
  }
}

.modal-backdrop-custom {
  background-color: rgba(18, 51, 102, .7);
}

.mytable {
  width: 100%;
  // margin:0 auto;
  // max-width:960px;
  border-collapse: collapse;
  color: #212529;
  th {
    font-size: 0.875rem;
  }
}
.mytable td {
  border: 1px solid rgb(209, 209, 209);
  padding: 5px;
  vertical-align: top;
}
p {
  margin: 0 0 1em;
}

@media screen and (max-width: 601px) {
  table.mobile-optimised {
    word-wrap: break-word;
  }
  table.mobile-optimised thead {
    position: absolute;
    left: -999em;
    top: -999em;
  }
  table.mobile-optimised td {
    display: block;
    float: left; /* ie9 and under hack */
    width: 100%;
    clear: both;
    background: #ffffff;
    padding: 10px 5px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  table.mobile-optimised tbody,
  table.mobile-optimised tr {
    display: block;
  }
  .mobile-optimised td:before {
    content: attr(data-th);
    display: block;
    font-weight: bold;
    font-size: 0.875rem;
    margin: 0 0 2px;
    color: #000;
  }
  .mobile-optimised tbody tr {
    float: left;
    width: 100%;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 602px) {
  .display-hide-sm {
    display: none;
  }
}
@media screen and (max-width: 601px) {
  .display-show-sm {
    display: none;
  }
}
@media screen and (max-width: 1024px) {
  #sign-up-background-image-col {
    display: none;
  }
}

::-webkit-scrollbar {
  width: 12px;
  scroll-behavior: smooth;
}
// ::-webkit-scrollbar:hover {
//   width: 18px;
// }
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
}
::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 20px;
}

.text-themed {
  color: $theme-blue
}

.bg-themed {
  color: $white;
  background-color: $theme-blue
}


.line-height-24 {
  line-height: 24px;
}

.wallet-available-box {
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
  border-radius: 0px;
  margin: 11px 4px;
  padding: 55px 65px 55px 56px;
  min-height: 218px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 80px;
  // flex-direction: column;
  align-items: center;

  .wallet-balance {
    div {
      display: flex;
      align-items: center;
      gap: 10px;

      p {
        margin: 0px;
        font-weight: 500;
        font-size: 16px;
        line-height: 149.5%;
        color: #11284B;
      }
      span {
        margin-top: 5px;
      }
    }
    h3 {
      font-size: 40px;
      line-height: 55px;
      display: flex;
      align-items: center;
      color: #23549F;
      margin-top: 15px;
      font-weight: 800;
    }
  }

  button {
    min-height: 72px;
    min-width: 246.75px;
    background: #0869B6;
    border-radius: 6px;
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.25px;
    color: #FFFFFF;
    border: none;
    padding: 17.1146px 41.075px;
  }
}

.fund-wallet-modal-section-one {
  padding: 40px 75px 40px 58px;
  background: #FFFFFF;

  .wallet-details {
    h2 {
      font-family: 'IBM Plex Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 29px;
      color: #11284B;
    }
  }
  .fund-wallet-modal-separator {
    border: 1.5px solid #F2F2F2;
    width: 100%;
  }
  .first-paragraph {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -0.014em;
    color: rgba(17, 40, 75, 0.85);
    margin-top: 35px;
    margin-bottom: 33px;
    padding-right: 20px;
  }
  .account-number-div {
    display: flex;
    justify-content: space-between;

    div {
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: -0.25px;
        color: #428BCA;
        margin: 0px;
      }
      h6 {
        font-weight: 500;
        font-size: 22px;
        line-height: 32px;
        letter-spacing: -0.25px;
        color: #11284B;
        margin: 0px;
        margin-top: 14px;
      }
    }
    .copy-btn {
      background: rgba(205, 220, 244, 0.93);
      border: 1px solid #5086D8;
      border-radius: 3.68px;
      font-weight: 800;
      font-size: 19.6267px;
      color: #5086D8;
      min-width: 92px;
      min-height: 39.25px;
    }
  }
  .kindly-note {
    display: flex;
    gap: 10px;
    align-items: center;
    
    p {
      font-weight: 800;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.014em;
      color: #6B6F80;
    }
  }
}

.fund-wallet-modal-section-two {
  padding: 40px 75px 40px 58px;
  background: #FFFFFF;

  h4 {
    font-weight: 800;
    font-size: 22px;
    line-height: 34px;
    letter-spacing: -0.5px;
    color: #11284B;
    margin-bottom: 29px;
    text-align: center;
  }

  .first-section {
    h4 {
      font-weight: 500;
      font-size: 24px;
      line-height: 40px;
      letter-spacing: -0.5px;
      color: #11284B;
      margin-bottom: 29px;
    }
    .paragraphs-list-box {
      display: flex;
      flex-direction: column;
      gap: 20px;

      div {
        display: flex;
        gap: 16px;
        align-items: center;

        p {
          margin: 0px;
          margin-top: 3px;

          span {
            font-weight: 800;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 602px) {
 .fund-wallet-modal-section-one {
  padding: 40px 0px;
 }
 .fund-wallet-modal-section-two {
   padding: 40px 0px;
 }
 .wallet-available-box {
  flex-direction: column;
  align-items: center;
 }
 .wallet-available-box {
        padding: 55px 20px 55px 20px;
    button {
      min-height: 72px;
      min-width: 100%;
      width: 100%;
      background: #0869B6;
      border-radius: 6px;
      font-weight: 800;
      font-size: 18px;
      line-height: 32px;
      letter-spacing: -0.25px;
      color: #FFFFFF;
      border: none;
      padding: 17.1146px 25.075px;
    }
  }
}