.ant-form-item-explain-error {
    color: #ff4d4f;
    text-align: left;
}
.ant-input {
    height: 30px !important;
}

 .ant-btn-themed {
    background-color: #123366 !important;
    border-color: #123366 !important; 
    color: #fff !important;
   height: 50px !important;
}
.ant-btn-secondary {
    color: #212529 !important;
    background-color: #dde4eb !important;
    border-color: #dde4eb !important;
    height: 50px !important;
}
.ant-btn-themed-h-0 {
     background-color: #123366 !important;
    border-color: #123366 !important; 
    color: #fff !important;
}
.ant-btn-link {
    color: #123366 !important;
}
::selection {
    background: #123366 !important;
}
.ant-form-item-feedback-icon-success {
    color: #123366 !important;
}
.ant-form-item-explain-error {
    margin-bottom: 15px;
}
.ant-message .anticon {
    top: -3px !important;
}

@media screen and (max-width: 601px) {
    .ant-row {
        display: block !important;
    }
}
.ant-message {
    z-index: 1500 !important;
}
.ant-input-textarea .ant-input {
    height: 100px !important;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    height: 40px !important;
    padding: 3px 15px !important;
}
.ant-input.ant-input-disabled {
    height: 30px !important;
}
.ant-input.ant-input-disabled.register-age-disabled {
    height: 40px !important;
}
.ant-picker-input > input {
    height: 30px !important;
}
.register-legend-title {
    font-size: 24px !important;
    color: rgba(0, 0, 0, 0.85) !important;
}
.ant-notification {
    z-index: 2000 !important;
}
.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
    opacity: .65 !important;
}
.ant-form-item-label {
    display: block !important;
    text-align: left !important;
}
.consultation-time-box-badge.past-time {
    opacity: .6 !important;
}
.ant-select-selection-item {
    text-align: left !important;
}

.ant-alert-message {
    line-height: 24px !important;
}

@media screen and (max-width: 601px) {
   .mt-sm-20 {
    margin-top: 20px;
    }
    .p-sm-0 {
        padding: 0px !important;
    }
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
        flex-wrap: wrap;
        gap: 10px;
    }
    .ant-tabs-tab + .ant-tabs-tab {
        margin: 0px !important;
    }
    .content-wrapper {
        padding: 1rem 1rem 0 1rem;
    }
}