/* Sidebar */
// .content-wrapper {
//   margin-left: $sidebar-width-lg;
// }
.sidebar {
  // min-height: calc(100vh - #{$navbar-height});
  background: $sidebar-light-bg;
  font-weight: 500;
  font-family: $type1;
  padding: 0;
  width: $sidebar-width-lg;
  z-index: 11;
  position: fixed;
  top: 0;
  bottom: 0;
  overflow: hidden !important;


  transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  -webkit-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  -moz-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  -ms-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  border-right: 2px solid #efefef;
  .sidebar-brand-wrapper {
    width: auto;
    height: $navbar-height;
    border-bottom: 1px solid lightgrey;

    .sidebar-brand {
      // padding: 2.25rem 0 0.25rem 40px;
      text-align: center;
      display: inline-block;
      margin: 0 auto;

      .rtl & {
        padding: 20px 61px 10px 0;
      }

      img {
        width: calc(#{$sidebar-width-lg} - 130px);
        max-width: 100%;
        height: 48px;
        margin: auto;
        vertical-align: middle;
      }
    }

    .brand-logo-mini {
      display: none;
      img {
        width: calc(#{$sidebar-width-icon} - 20px);
        max-width: 100%;
        height: 30px;
        margin: auto;
      }
    }
  }
  .nav {
    overflow: hidden;
    flex-wrap: nowrap;
    flex-direction: column;
    

    .nav-item {
      .collapse {
        z-index: 999;
      }

      // .collapse.show,
      .collapsing {
        background: $sidebar-light-menu-active-bg;
      }

      .nav-link {
        align-items: center;
        display: flex;
        padding: $sidebar-menu-padding;
        white-space: nowrap;
        height: $nav-link-height;
        color: $sidebar-light-menu-color;

        i {
          &.menu-arrow {
            margin-left: auto;
            margin-right: 0;
            transition-duration: 0.2s;
            transition-property: transform;
            transition-timing-function: ease-in;

            &:before {
              content: "\F142";
              font-family: "Material Design Icons";
              font-size: 18px;
              line-height: 1;
              font-style: normal;
              vertical-align: middle;
              color: rgba($sidebar-light-menu-color, 0.5);
            }
          }
        }

        &[aria-expanded="true"] {
          background: $sidebar-light-menu-active-bg;

          i {
            &.menu-arrow {
              transform: rotate(90deg);
            }
          }
        }

        .menu-icon {
          margin-right: 1.25rem;
          width: $sidebar-icon-size;
          line-height: 1;
          font-size: 18px;
          color: $sidebar-light-menu-icon-color;

          .rtl & {
            margin-right: 0;
            margin-left: 1.25rem;
          }
        }

        .menu-title {
          color: inherit;
          display: inline-block;
          font-size: $sidebar-menu-font-size;
          line-height: 1;
          vertical-align: middle;
          cursor: pointer;
          font-weight: 400;
        }

        .badge {
          margin-left: auto;
        }

        &:hover {
          color: darken($sidebar-light-menu-color, 5%);
        }
      }

      &.active {
        >.nav-link {
          color: $sidebar-light-menu-active-color;
          // box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 4px 2px;
          background-color: $sidebar-light-menu-active-bg;
          border-right: 2px solid $theme-green;
         
          .menu-title,
          i {
            color: inherit;
            font-weight: 800;
          }
        }
      }

      &.nav-profile {
        .nav-link {
          display: flex;
          flex-direction: column;
          height: auto;

            .profile-image {
              margin: auto;
              display: grid;
              align-items: center;
              justify-content: center;
              margin-bottom: 24px;

              img {
                width: 100px;
              height: 100px;
              border-radius: 50% !important;
              }
            }

            .text-wrapper {
              margin-left: 15px;

              .rtl & {
                margin-left: 0;
                margin-right: 15px;
              }

            }

            .profile-name {
              font-weight: 500;
              margin-bottom: 10px;
              line-height: 18px;
              text-align: center;
              max-width: 200px;
              overflow-wrap: break-word;
            }

            .designation {
              margin-right: 3px;
              line-height: 1;
            }
            .dropdown {
              .dropdown-toggle {
                &.arrow-hide {
                  &:after {
                    display: none;
                  }
                }
              }
              
            }
            .dropdown {
              position: unset;
              //position: absolute;
              //background: red;

              .user-switch-dropdown-toggler {
                display: flex;
                flex-direction: row;
                padding: 1px 0px;
                margin-bottom: 30px;
                &:focus {
                  outline: 0;
                  box-shadow: none;
                }
              }

              .dropdown-menu {
                top: 0;
              }
            }
        }
      }
      &.not-navigation-link {
        position: relative;
      }
    }

    &:not(.sub-menu) {
      >.nav-item {
        &:hover {
          &:not(.nav-profile) {
            >.nav-link {
              background: $sidebar-light-menu-hover-bg;
              color: $sidebar-light-menu-hover-color;
            }
          }
        }
      }
    }

    &.sub-menu {
      margin-bottom: 0;
      padding:0;
      

      .nav-item {
        .nav-link {
          color: $sidebar-light-submenu-color;
          padding: $sidebar-submenu-item-padding;
          font-size: $sidebar-submenu-font-size;
          line-height: 1;
          height: auto;
          display: flex;
          justify-content: center;
          text-align: left;
          font-weight: 400;

          &.active {
            // color: $sidebar-light-menu-active-color;
            // background: transparent;
            // box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 4px 2px;
            color: $sidebar-light-menu-active-color;
           box-shadow: none !important;
            background-color: $sidebar-light-menu-active-bg;
            border-right: 2px solid $theme-green;
            font-weight: 800;
          .menu-title,
          i {
            color: inherit;
             font-weight: 800;
          }

            &:before {
              background: $sidebar-light-menu-active-color;
            }
          }
        }

        &:hover {
          >.nav-link {
            background: $sidebar-light-submenu-hover-bg;
            color: $sidebar-light-submenu-hover-color;

            &:before {
              background: $sidebar-light-submenu-hover-color;
            }
          }
        }
      }
    }
  }
}

//sidebar color variation
.sidebar-dark {
  .sidebar {
    background: $sidebar-dark-bg;

    .nav {
      .nav-item {

        .collapse.show,
        .collapsing {
          background: $sidebar-dark-menu-active-bg;
        }

        .nav-link {
          color: $sidebar-dark-menu-color;

          &[aria-expanded="true"] {
            background: $sidebar-dark-menu-active-bg;
          }

          i:not(.btn i) {
            color: $sidebar-dark-menu-icon-color;

            &.menu-arrow {
              &:before {
                color: rgba($sidebar-dark-menu-color, 0.5);
              }
            }
          }

          &:hover {
            color: darken($sidebar-dark-menu-color, 5%);
          }
        }

        &.nav-profile {
          .profile-name {
          
            .name {
              color: $sidebar-dark-profile-name-color;
            }

            .designation {
              color: $sidebar-dark-profile-title-color;
            }
          }

          .notification-panel {
            &:before {
              background: $sidebar-dark-profile-name-color;
            }

            >span {
              background: $sidebar-dark-menu-active-bg;

              i {
                color: color(gray-light);
              }
            }
          }
        }

        &.active {
          >.nav-link {
            color: $sidebar-dark-menu-active-color;
          }
        }

        .sidebar-sticker {
          background: $sidebar-dark-menu-active-bg;
        }
      }

      &:not(.sub-menu) {
        >.nav-item {
          &:hover {
            &:not(.nav-profile) {
              >.nav-link {
                background: $sidebar-dark-menu-hover-bg;
                color: $sidebar-dark-menu-hover-color;
              }
            }
          }
        }
      }

      &.sub-menu {
        .nav-item {
          .nav-link {
            color: $sidebar-dark-submenu-color;

            &.active {
              color: $sidebar-dark-menu-active-color;

              &:before {
                background: $sidebar-dark-menu-active-color;
              }
            }
          }

          &:hover {
            >.nav-link {
              background: $sidebar-dark-submenu-hover-bg;
              color: $sidebar-dark-submenu-hover-color;

              &:before {
                background: $sidebar-dark-submenu-hover-color;
              }
            }
          }
        }
      }
    }
  }
}

/* style for off-canvas menu*/

@media screen and (max-width: 991px) {
  
.sidebar .sidebar-brand-wrapper {
  display: none !important;
}
.sidebar .sidebar-brand-wrapper .sidebar-brand img{
    display: none !important;
}
.sidebar .nav .nav-item.nav-profile .nav-link {
  margin-top: 40px;
}
  .sidebar-offcanvas {
    position: fixed;
    max-height: calc(100vh - #{$navbar-height});
    top: $navbar-height;
    bottom: 0;
    overflow: auto;
    right: -$sidebar-width-lg;
    -webkit-transition: all 0.25s ease-out;
    -o-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
    

    &.active {
      right: 0;
    }
  }
}

// @media screen and (max-width: 600px) {
//   .content-wrapper {
//   margin-left: 0px;
// }
  
// }


@media screen and (min-width: 991px) {
  .page-body-wrapper {
 width: calc(100% - 0px);
}
.content-wrapper-m {
  margin-left: 255px;
}
.content-wrapper-sm {
  margin-left: 77px;
}
}
.overflow-nav {
  height: 100%;
   overflow-y: auto;
   padding-bottom: 120px;
}


::-webkit-scrollbar {
    width: 6px !important; 
    height: 6px;
}