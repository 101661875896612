@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

@font-face {
    font-family: 'Avenir';
    src: url('../fonts/Avenir\ Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('../fonts/Avenir\ Black.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('../fonts/Avenir\ Heavy.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('../fonts/Avenir\ Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('../fonts/Avenir\ Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}