/* Forms */
.otp-input {
  padding-left: 15px;
  letter-spacing: 42px;
  border: 0;
  background-image: linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 50px 1px;
  background-repeat: repeat-x;
  background-position-x: 35px;
}

.form-group {
  margin-bottom: 1.5rem;
}

.input-group-append,
.input-group-prepend {
  color: $input-placeholder-color;
  width: auto;
  border: none;
  .input-group-text {
    border-color: $border-color;
    padding: 0.94rem 0.5rem 0.94rem 1rem;
    color: $input-placeholder-color;
  }
  button {
    padding-top: .3rem;
    padding-bottom: .3rem;
  }
}

.custom-control {
  .custom-control-label {
    line-height: 1.6;
    margin-bottom: 0;
  }
}

.form-control {
  border: 1px solid $border-color;
  font-family: $type1;
  font-size: $input-font-size;
  font-weight: 400;
  padding: 8px 12px;
}

select {
  &.form-control {
    padding: .4375rem .75rem;
    border: 0;
    outline: 1px solid $border-color;
    color: $input-placeholder-color;
    &:focus {
      outline: 1px solid $border-color;
    }
    @each $color,
    $value in $theme-colors {
      &.border-#{$color} {
        outline: 1px solid $value;
        &:focus {
          outline: 1px solid $value;
        }
      }
    }
  }
}

.form-group {
  label {
    font-size: $default-font-size;
    line-height: 1;
    vertical-align: top;
    margin-bottom: .5rem;
  }
  &.has-danger {
    .form-control {
      border-color: theme-color(danger);
    }
  }
  .file-upload-default {
    visibility: hidden;
    position: absolute;
  }
  .file-upload-info {
    background: transparent;
  }
}
.form-check-input {
  margin-left: 0;
}

.consultation-fee-box {
  border: 1px solid #0869B6;
  background: #EFF7FD !important;
  color: #0869B6 !important;
  border-radius: 5px;
}
.fs-consultation-fee-label-color {
   color: #0869B6 !important;
}

.fs-12 {
  font-size: 12px !important;
}

.consultation-time-box {
  display: flex;
  flex-wrap: wrap;
  padding: 0px;
  margin: 0px;
}

.consultation-time-box-badge {
  padding: 10px;
  background: #f4f7fa;
  
  margin-right: 10px;
  margin-top: 10px;
  width: 80px;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
}
.consultation-time-box-badge-active {
   background: #123366;
  color: #f4f7fa;
   padding: 10px;
  margin-right: 10px;
  margin-top: 10px;
  width: 80px;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
}