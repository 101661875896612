/* Cards */
.card-diagnosis{
  box-shadow: rgba(192, 2, 209, 0.52) -5px 2px !important;
}
.card-vitals{
  box-shadow: rgba(197, 4, 78, 0.52) -5px 2px !important;
}
.card-lab{
  box-shadow: $theme-green -5px 2px !important;
}
.card-radiology{
  box-shadow: rgba(3, 22, 192, 0.52) -5px 2px !important;
}
.card-prescription{
  box-shadow: rgba(207, 84, 2, 0.52) -5px 2px !important;
}
.card-timeline-header-diagnosis {
  color: rgba(192, 2, 209, 0.52) !important;
}
.card-timeline-header-radiology {
  color: rgba(3, 22, 192, 0.52) !important;
}
.card-timeline-header-lab {
  color: $theme-blue !important;
}
.card-timeline-header-vitals {
  color: rgba(197, 4, 78, 0.52) !important;
}
.card-timeline-header-prescription {
  color: rgba(207, 84, 2, 0.52) !important;
}

.card-timeline {
  
  // box-shadow: rgba(4, 112, 47, 0.52) -5px 2px !important;
  text-align: left !important;
  border-radius: 10px;
  .card-timeline-header {
    // color: $theme-green;
    font-weight: bold;
    margin-bottom: 1px;
  }
  
  .text-muted {
    color: #a7a4a4 !important;
  }
}

.card {
  border: none;
  box-shadow: $card-shadow-color;
  .card-body {
    padding: $card-padding-y $card-padding-x;
    + .card-body {
      padding-top: 1rem;
    }
    .card-icon-indicator {		
      width: 45px;		
      height: 45px;		
      font-size: 20px;		
      color: $white;		
      border-radius: 50px;		
      text-align: center;		
      line-height: 2.2;		
    }
  }
  .card-title {
    font-weight: 500;
    color: $card-title-color;
    margin-bottom: 15px;
    text-transform: capitalize;
    font-family: $type1;
    font-size: 14px;
  }
  .card-subtitle {
    @extend .text-gray;
    font-family: $type1;
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  
  }
  .card-description {
    color: $card-description-color;
    margin-bottom: 1.5rem;
    font-family: $type1;
    font-weight: 400;
    color: $card-description-color;
  }
  &.card-outline-success {
		border: 1px solid theme-color("success");
	}
	&.card-outline-primary {
		border: 1px solid theme-color("primary");
	}
	&.card-outline-warning {
		border: 1px solid theme-color("warning");
	}
	&.card-outline-danger {
		border: 1px solid theme-color("danger");
	}
	&.card-rounded {
		@include border-radius(5px);
	}

  &.card-faded {
    background: #b5b0b2;
    border-color: #b5b0b2;
  }
  &.card-circle-progress {
    color: $white;
    text-align: center;
  }
  &.card-img-holder {
    position: relative;
    .card-img-absolute {
      position: absolute;
      top:0;
      right: 0;
      height: 100%;
    }
  }
}

@each $color, $value in $theme-colors {
  .card-inverse-#{$color} {
    @include card-inverse-variant(rgba(theme-color($color), .2), theme-color-level($color, 1), theme-color-level($color, 3));
  }
}
